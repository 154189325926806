import { Form, Formik } from "formik";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import Box from "../components/Box/Box";
import { MedicalVisit } from "../types/FormTypes";
import * as Yup from "yup";
import Progressbar from "../components/Progressbar/Progressbar";
import { MultipleFileUploadField } from "../components/FileUpload/MultipleFileUploadField";
import { medicalVisitAtom } from "./AddCertificate";
import BackButton from "../components/Buttons/BackButton";
import ErrorList from "../components/Form/ErrorList";
import { useRef } from "react";
import { handleSubmitClick } from "../components/Form/FormUtils";
import { useIsAddMoreTrue } from "./AddMore";
import useFocusOnLoad from "../hooks/useFocusOnLoad";

export default function ByPublic() {
  const h2Ref = useFocusOnLoad<HTMLHeadingElement>();

  const [formData, setFormData] = useAtom(medicalVisitAtom);
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const [isAddMoreTrue] = useIsAddMoreTrue();

  return (
    <div>
      <Formik<MedicalVisit>
        onSubmit={(values) => {
          setFormData({
            ...formData,
            ...values,
          });
          navigate("../kostnader-for-overnattning");
        }}
        initialValues={{
          ...formData,
          ...{
            dateTo: "",
            dateFrom: "",
            travelDescription: "",
          },
        }}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize
        validationSchema={Yup.object().shape({
          receipts: Yup.array().min(
            1,
            "Vänligen bifoga ett intyg innan du går vidare."
          ),
        })}
      >
        {({ errors, touched, values }) => (
          <>
            <div className="flex justify-between items-center">
              <div className="flex justify-center h-14 md:h-auto mb-1">
                <div className="w-full text-left md:hidden">
                  <BackButton />
                </div>
              </div>
              <div className="w-40 xs:w-52 md:w-full max-w-xs md:mb-4">
                {isAddMoreTrue ? (
                  <Progressbar percentage={"84"} />
                ) : (
                  <Progressbar percentage={"56"} />
                )}
              </div>
            </div>
            <Form ref={formRef}>
              <Box>
                <>
                  <h2 ref={h2Ref} tabIndex={-1}>
                    Om resan
                  </h2>

                  <ErrorList errors={errors} />

                  <h3>Resa med kollektivtrafik, taxi eller flyg</h3>
                  <p>
                    Här bifogar du alla resekvitton till den vårdgivare som står
                    i intyget du bifogade i steg innan.{" "}
                  </p>

                  <p>
                    Det viktigaste är att priset framgår i dokumentet du
                    bifogar. Till exempel accepteras inte enbart en QR-kod.{" "}
                  </p>
                  <p className="mb-8">OBS! Resa med sjuktaxi ersätts ej.</p>

                  <MultipleFileUploadField
                    currentFiles={values.receipts}
                    multiple={true}
                    label="Bifoga kvitton eller biljetter för din resa (Obligatorisk)"
                    inputId="receipts"
                    inputName="receipts"
                  />
                </>
              </Box>

              <div className="flex justify-center">
                <div className="w-full text-left flex items-center">
                  <BackButton />
                </div>
                <div className="w-full text-right">
                  <button
                    onClick={handleSubmitClick(formRef)}
                    className="btn secondary"
                    type="submit"
                  >
                    Gå vidare
                  </button>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
}
