import * as Yup from "yup";
import { TimePeriod } from "../types/FormTypes";

Yup.addMethod(
  Yup.string,
  "isOccurredDate",
  function IsOccurredDate(messaage?: string) {
    return this.test(
      "valid-date",
      messaage ?? "Vänligen välj ett datum som inträffat.",
      (value) => {
        let today = new Date(Date.now()).toLocaleDateString("sv-SE");
        return value !== undefined && IsBefore(value, today, true);
      }
    );
  }
);
Yup.addMethod(
  Yup.string,
  "isDateSameOrAfter",
  function IsDateSameOrAfter(than: string, message?: string) {
    return this.when(than, ([other], schema) => {
      return !other
        ? schema
        : schema.test(
            "valid-date-combo",
            message ?? "Vänligen välj ett senare datum.",
            (value) => {
              return value !== undefined && IsBefore(other, value, true);
            }
          );
    });
  }
);
Yup.addMethod(
  Yup.string,
  "isDateNoEarlierThan",
  function IsDateNoEarlierThan(
    amount: number,
    period: TimePeriod.Year | TimePeriod.Month | TimePeriod.Day,
    message?: string
  ) {
    return this.test(
      "valid-min-date",
      message ??
        "Vänligen välj ett datum som inte är längre tillbaka än ett år",
      (value) => {
        if (value === undefined) return false;

        let today = new Date(Date.now()).toLocaleDateString("sv-SE").split("-");
        let year = today[0];
        let month = today[1];
        let day = today[2];
        var date;
        if (period === TimePeriod.Year) {
          date = `${Number.parseInt(year) - amount}-${month}-${day}`;
        }
        if (period === TimePeriod.Month) {
          date = `${year}-${Number.parseInt(month) - amount}-${day}`;
        }
        if (period === TimePeriod.Day) {
          date = `${year}-${month}-${Number.parseInt(day) - amount}`;
        }

        return date !== undefined && !IsBefore(value, date);
      }
    );
  }
);

function IsBefore(earlier: string, than: string, orSameAs?: boolean) {
  var input = earlier.split("-");
  var max = than.split("-");
  var yearLess = input[0] < max[0];
  var yearSame = input[0] === max[0];
  var monthLess = input[1] < max[1];
  var monthSame = input[1] === max[1];
  var dayLess = input[2] < max[2];
  var dayMore = input[2] > max[2];

  return (
    yearLess ||
    (yearSame && monthLess) ||
    (yearSame && monthSame && (dayLess || (orSameAs && !dayMore)))
  );
}
