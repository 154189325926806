import { FileError } from "react-dropzone";

export interface MedicalTravelModel {
  compensationfor: string;
  patientssn: string;
  patientfirstname: string;
  patientlastname: string;
  patientemail: string;
  patientphone: string;
  caregiverssn: string;
  caregiverfirstname: string;
  caregiverlastname: string;
  caregiveremail: string;
  caregiverphone: string;
  medicalVisits: MedicalVisit[];
}

export interface MedicalVisit {
  certificate: UploadableFile[];
  wayOfTravel: string;
  receipts: UploadableFile[];
  dateTo: string;
  dateFrom: string;
  travelDescription: string;
  comments: string;
  otherFiles: UploadableFile[];
}

export interface UploadableFile {
  id: number;
  file: File;
  data: string;
  size: number;
  errors: FileError[];
}

export enum TimePeriod {
  Year,
  Month,
  Week,
  Day,
  Hour,
  Minute,
  Second,
  MilliSecond,
}
