import { Form, Formik } from "formik";
import { atom, useAtom } from "jotai";
import { useNavigate, useParams } from "react-router-dom";
import Box from "../components/Box/Box";
import { formDataAtom } from "../App";
import { MedicalVisit } from "../types/FormTypes";
import * as Yup from "yup";
import Progressbar from "../components/Progressbar/Progressbar";
import { MultipleFileUploadField } from "../components/FileUpload/MultipleFileUploadField";
import Accordion from "../components/Accordion/Accordion";
import BackButton from "../components/Buttons/BackButton";
import ErrorList from "../components/Form/ErrorList";
import { useRef } from "react";
import { handleSubmitClick } from "../components/Form/FormUtils";
import { useIsAddMoreTrue } from "./AddMore";
import useFocusOnLoad from "../hooks/useFocusOnLoad";

export const medicalVisitAtom = atom<MedicalVisit>({
  certificate: [],
  wayOfTravel: "",
  receipts: [],
  travelDescription: "",
  comments: "",
  dateTo: "",
  dateFrom: "",
  otherFiles: [],
});

export default function AddCertificate() {
  const h2Ref = useFocusOnLoad<HTMLHeadingElement>();
  const [isAddMoreTrue] = useIsAddMoreTrue();
  const { id } = useParams();
  const index = id === undefined ? 0 : Number.parseInt(id) - 1;
  const [formData, setFormData] = useAtom(medicalVisitAtom);
  const [mainFormData] = useAtom(formDataAtom);
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);

  return (
    <div>
      <Formik<MedicalVisit>
        onSubmit={(values) => {
          setFormData({
            ...formData,
            ...values,
          });
          navigate("../om-resan");
        }}
        initialValues={{
          ...(mainFormData.medicalVisits[index] ?? formData),
        }}
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object().shape({
          certificate: Yup.array().min(
            1,
            "Vänligen bifoga ett intyg innan du går vidare."
          ),
        })}
      >
        {({ errors, touched, values }) => (
          <>
            <div className="flex justify-between items-center">
              <div className="flex justify-center h-14 md:h-auto mb-1">
                <div className="w-full text-left md:hidden">
                  <BackButton />
                </div>
              </div>

              <div className="w-40 xs:w-52 md:w-full max-w-xs md:mb-4">
                {/* Stay on same percentage if choosen to add more medicalvisits */}
                {isAddMoreTrue ? (
                  <Progressbar percentage={"84"} />
                ) : (
                  <Progressbar percentage={"28"} />
                )}
              </div>
            </div>
            <Form ref={formRef}>
              <Box>
                <>
                  <h2 ref={h2Ref} tabIndex={-1}>
                    Vårdbesök
                  </h2>

                  <ErrorList errors={errors} />

                  <h3>Intyg för vårdbesök från vårdgivare</h3>

                  <p>
                    Du måste ha intyg eller kvitto som styrker ditt vårdbesök,
                    kallelser godkänns ej som kvitto. Patienter med frikort
                    skall begära kvitto. Intyget ska innehålla patientens
                    personuppgifter, datum för vårdbesöket samt vårdgivare.
                  </p>
                  <p>
                    Du kan bifoga ett intyg i taget. Om du har flera intyg och
                    vill ansöka om ersättning för flera vårdbesök samtidigt,
                    kommer du att få en fråga om det senare i formuläret.
                  </p>

                  <div className="mb-6">
                    <Accordion>
                      <p>
                        Om du saknar ett intyg måste du kontakta din klinik (där
                        sjukvårdsbesöket har ägt rum) och be dem att skicka
                        intyget till dig. Se till att intyget innehåller:
                      </p>

                      <ul>
                        <li>Patientens namn (och vårdnadshavarens)</li>
                        <li>Patientens personnummer (och vårdnadshavarens)</li>
                        <li>Vårdgivare</li>
                        <li>Datum för vårdbesök</li>
                      </ul>
                    </Accordion>
                  </div>

                  <MultipleFileUploadField
                    currentFiles={values.certificate}
                    multiple={false}
                    type="intyg"
                    label="Bifoga ett intyg för ditt vårdbesök (Obligatorisk)"
                    inputId="certificate"
                  />
                </>
              </Box>

              <div className="flex justify-center">
                <div className="w-full text-left flex items-center">
                  <BackButton />
                </div>
                <div className="w-full text-right">
                  <button
                    onClick={handleSubmitClick(formRef)}
                    className="btn secondary"
                    type="submit"
                  >
                    Gå vidare
                  </button>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
}
