export const handleSubmitClick =
  (formRef: React.RefObject<HTMLFormElement>) =>
  (event: React.MouseEvent<HTMLButtonElement>) => {
    // Scroll to the top of the form
    formRef.current?.scrollIntoView({
      behavior: "smooth",
      inline: "start",
    });
    setTimeout(() => {
      const validationErrorH3 = document.querySelector(
        ".error-box-wrapper"
      ) as HTMLElement;

      if (validationErrorH3) {
        validationErrorH3.focus();
      }
    }, 300);
  };
