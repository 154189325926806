import { useEffect, useRef } from "react";

function useFocusOnLoad<T extends HTMLElement>(shouldFocus: boolean = true) {
  const ref = useRef<T | null>(null);

  useEffect(() => {
    if (shouldFocus && ref.current) {
      ref.current.focus();
    }
  }, [shouldFocus]);

  return ref;
}

export default useFocusOnLoad;
