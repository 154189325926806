import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, useField } from "formik";

interface InputTextProps {
  name: string;
  label: string;
  moreinfo?: string;
  type?: string;
  ssn?: boolean;
  phonenumber?: boolean;
  autocomplete?: string;
}

const InputText = ({
  name,
  label,
  moreinfo,
  type = "text",
  ssn,
  phonenumber,
  autocomplete = "off",
}: InputTextProps) => {
  const [field, meta, helpers] = useField(name);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (ssn) {
      const ssnVal = e.target.value;
      if (ssnVal && ssnVal.length === 10) {
        const formattedSsn = `${ssnVal.substring(0, 6)}-${ssnVal.substring(6)}`;
        helpers.setValue(formattedSsn);
      }
    }

    if (phonenumber) {
      const phoneVal = e.target.value;
      // Remove spaces and hyphens
      let formattedSsn = phoneVal.replace(/[\s-]/g, '');

      helpers.setValue(formattedSsn);
    }

  };

  const handleInputChange = (e: React.FocusEvent<HTMLInputElement>) => {
    const val = e.target.value;
    helpers.setValue(val);
  };

  const handleClearField = () => {
    helpers.setValue("");
  };

  return (
    <>
      <label htmlFor={name} className="required">
        <span>{label}</span>
        {moreinfo && (
          <span className="more-info">
            <span aria-hidden="true">{moreinfo}</span>
            {ssn && (
              <span className="show-for-sr">
                i formatet, år, månad, dag och de fyra sista siffrorna.
              </span>
            )}
          </span>
        )}
        {meta.error && (
          <span className="validation-error">
            {meta.error.toString()}{" "}
            {ssn && (
              <>
                <span aria-hidden="true">(ÅÅMMDD-XXXX)</span>
                <span className="show-for-sr">
                  i formatet, år, månad, dag och de fyra sista siffrorna.
                </span>
              </>
            )}
          </span>
        )}
      </label>
      <div
        className={`input-wrapper ${ssn ? "short" : "long"} ${meta.error ? "has-error" : ""
          } `}
      >
        <Field
          required
          className={`${ssn ? "short" : "long"} ${meta.error ? "has-error" : ""
            } `}
          type={type}
          {...field}
          onBlur={handleBlur}
          onChange={handleInputChange}
          id={name}
          name={name}
          value={field.value}
          autoComplete={autocomplete}
        />
        {field.value.length > 0 && (
          <button
            className="clear-button"
            onClick={handleClearField}
            type="button"
          >
            <div className="show-for-sr">Rensa fältet, {label}</div>
            <FontAwesomeIcon icon={solid("times")} />
          </button>
        )}
      </div>
    </>
  );
};

export default InputText;
