// import { Button, Grid } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used

import React from "react";

export interface FileHeaderProps {
  file: File;
  onDelete: (file: File) => void;
}

export function FileHeader({ file, onDelete }: FileHeaderProps) {
  return (
    <div className="flex justify-between items-center">
      <div title={file.name} className="file-name">
        {file.name} ({(file.size / 1024).toFixed(0)}kb)
      </div>
      <div className="shrink-0">
        <button className="icon-link " onClick={() => onDelete(file)}>
          <FontAwesomeIcon
            className="text-base mr-2"
            icon={solid("trash-can")}
          />
          Ta bort <span className="show-for-sr">. Filen, {file.name}</span>
        </button>
      </div>
    </div>
  );
}
