import { Formik, Form } from "formik";
import { MedicalTravelModel } from "../types/FormTypes";
import { FOR_ME, FOR_PATIENT } from "./MedicalTravel";
import { useAtom } from "jotai";
import { formDataAtom } from "../App";
import { Link, useNavigate } from "react-router-dom";
import Progressbar from "../components/Progressbar/Progressbar";
import Box from "../components/Box/Box";
import * as Yup from "yup";
import { validateSwedishSsn } from "swedish-ssn";
import BackButton from "../components/Buttons/BackButton";
import ErrorList from "../components/Form/ErrorList";
import InputText from "../components/Form/InputText";
import { useRef } from "react";
import { handleSubmitClick } from "../components/Form/FormUtils";
import useFocusOnLoad from "../hooks/useFocusOnLoad";
export interface ContactDetailsFormValues extends MedicalTravelModel {
  patientemailrepeat: string;
  caregiveremailrepeat: string;
}

export default function ContactDetails() {
  const h2Ref = useFocusOnLoad<HTMLHeadingElement>();
  const [formData, setFormData] = useAtom(formDataAtom);
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const alaphaBetRegEx = /^[^a-ö]*$/;

  return (
    <div>
      <Formik<ContactDetailsFormValues>
        onSubmit={(values, { setSubmitting, setErrors }) => {
          setFormData({
            ...formData,
            ...values,
            ...{
              patientemailrepeat: undefined,
              caregiveremailrepeat: undefined,
            },
          });

          navigate("/reseuppgifter");
        }}
        initialValues={{
          ...(formData as ContactDetailsFormValues),
          ...(formData.compensationfor === FOR_PATIENT
            ? {
              patientemail: "",
              patientemailrepeat: "",
              patientphone: "",
              caregiveremailrepeat: formData.caregiveremail,
            }
            : {
              patientemailrepeat: formData.patientemail,
              caregiverssn: "",
              caregiverfirstname: "",
              caregiverlastname: "",
              caregiveremail: "",
              caregiveremailrepeat: "",
              caregiverphone: "",
            }),
        }}
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object().shape({

          patientssn: Yup.string()
            .required("Vänligen fyll i patientens personnummer")
            .matches(
              /^\d{6}-\d{4}$/,
              "Vänligen fyll i ett giltigt personnummer för patienten med endast 10 siffror "
            )
            .test(
              "valid-swedish-ssn",
              "Vänligen fyll i ett giltigt personnummer",
              (value) => {
                return validateSwedishSsn(value);
              }
            ),

          patientfirstname: Yup.string().required(
            "Vänligen fyll i patientens förnamn"
          ),

          patientlastname: Yup.string().required(
            "Vänligen fyll i patientens efternamn"
          ),

          patientemail: Yup.string().when("compensationfor", {
            is: FOR_PATIENT,
            then: (schema) => schema.notRequired(),
            otherwise: (schema) =>
              schema
                .email("Kontrollera att mejladressen är rätt")
                .required("Vänligen fyll i patientens mejladress"),
          }),

          patientemailrepeat: Yup.string().when("compensationfor", {
            is: FOR_PATIENT,
            then: (schema) => schema.notRequired(),
            otherwise: (schema) =>
              schema
                .email("Kontrollera att mejladressen är rätt")
                .required("Vänligen upprepa patientens mejladress")
                .oneOf(
                  [Yup.ref("patientemail")],
                  "Mejladress stämmer inte med förra inskrivningen"
                ),
          }),

          patientphone: Yup.string()
            .matches(alaphaBetRegEx, "Telefonnummer får inte innehålla bokstäver").when("compensationfor", {
              is: FOR_PATIENT,
              then: (schema) => schema
            }),

          caregiverssn: Yup.string().when("compensationfor", {
            is: FOR_PATIENT,
            then: (schema) =>
              schema
                .required("Vänligen fyll i vårdnadshavarens personnummer")
                .matches(
                  /^\d{6}-\d{4}$/,
                  "Vänligen fyll i ett giltigt personnummer för vårdnadshavaren med endast 10 siffror"
                )
                .test(
                  "valid-swedish-ssn",
                  "Vänligen fyll i ett giltigt personnummer",
                  (value) => {
                    return validateSwedishSsn(value);
                  }
                )
                .test(
                  "not-same-as-patientssn",
                  "Vårdnadshavarens personnummer får inte vara samma som patientens personnummer",
                  function (value) {
                    return value !== this.parent.patientssn;
                  }
                ),

            otherwise: (schema) => schema.notRequired(),
          }),
          caregiverfirstname: Yup.string().when("compensationfor", {
            is: FOR_PATIENT,
            then: (schema) =>
              schema.required("Vänligen fyll i vårdnadshavarens förnamn"),
            otherwise: (schema) => schema.notRequired(),
          }),
          caregiverlastname: Yup.string().when("compensationfor", {
            is: FOR_PATIENT,
            then: (schema) =>
              schema.required("Vänligen fyll i vårdnadshavarens efternamn"),
            otherwise: (schema) => schema.notRequired(),
          }),
          caregiveremail: Yup.string().when("compensationfor", {
            is: FOR_PATIENT,
            then: (schema) =>
              schema
                .email("Kontrollera att mejladressen är rätt")
                .required("Vänligen fyll i vårdnadshavarens mejladress"),
            otherwise: (schema) => schema.notRequired(),
          }),
          caregiveremailrepeat: Yup.string().when("compensationfor", {
            is: FOR_PATIENT,
            then: (schema) =>
              schema
                .email("Vänligen ange en korrekt epostadress")
                .required("Vänligen upprepa vårdnadshavarens mejladress")
                .oneOf(
                  [Yup.ref("caregiveremail")],
                  "Mejladress stämmer inte med förra inskrivningen"
                ),
            otherwise: (schema) => schema.notRequired(),
          }),

          caregiverphone: Yup.string().when("compensationfor", {
            is: FOR_PATIENT,
            then: (schema) =>
              schema
            .matches(alaphaBetRegEx, "Telefonnummer får inte innehålla bokstäver")

          }),

        })}
      >
        {({ errors, values, handleChange }) => (
          <>
            <div className="flex justify-between items-center">
              <div className="flex justify-center h-15 md:h-auto mb-1">
                <div className="w-full text-left md:hidden">
                  <BackButton />
                </div>
              </div>

              <div className="w-40 xs:w-52 md:w-full max-w-xs md:mb-4">
                <Progressbar percentage={"14"} />
              </div>
            </div>
            <Form ref={formRef} noValidate>
              <Box>
                <h2 ref={h2Ref} tabIndex={-1}>
                  Ange kontaktuppgifter
                </h2>

                <ErrorList errors={errors} />

                <h3>Patient</h3>

                <InputText
                  name="patientssn"
                  label="Patientens personnummer (Obligatorisk)"
                  moreinfo="ÅÅMMDD-XXXX (10 siffror)"
                  ssn
                />

                <InputText
                  name="patientfirstname"
                  label="Patientens förnamn (Obligatorisk)"
                  autocomplete={
                    formData.compensationfor === FOR_ME ? "given-name" : "off"
                  }
                />

                <InputText
                  name="patientlastname"
                  label="Patientens efternamn (Obligatorisk)"
                  autocomplete={
                    formData.compensationfor === FOR_ME ? "family-name" : "off"
                  }
                />

                {formData.compensationfor === FOR_PATIENT ? (
                  <>
                    <h3 className="mt-3">Vårdnadshavare </h3>

                    <InputText
                      name="caregiverssn"
                      label="Vårdnadshavarens personnummer (Obligatorisk)"
                      moreinfo="ÅÅMMDD-XXXX (10 siffror)"
                      ssn
                    />

                    <InputText
                      name="caregiverfirstname"
                      label="Vårdnadshavarens förnamn (Obligatorisk)"
                      autocomplete={"given-name"}
                    />

                    <InputText
                      name="caregiverlastname"
                      label="Vårdnadshavarens efternamn (Obligatorisk)"
                      autocomplete={"family-name"}
                    />



                    <h3 className="mt-10">Vårdnadshavarens kontaktuppgifter</h3>

                    <InputText
                      name="caregiveremail"
                      label="Vårdnadshavarens mejladress (Obligatorisk)"
                      autocomplete={"email"}
                      type="email"
                    />

                    <InputText
                      name="caregiveremailrepeat"
                      label="Upprepa mejladress (Obligatorisk)"
                      type="email"
                      autocomplete={"email"}
                    />

                    <InputText
                      name="caregiverphone"
                      label="Vårdnadshavarens telefonnummer (frivilligt)"
                      autocomplete={"tel"}
                      phonenumber

                    />
                  </>
                ) : (
                  <>
                    <h3 className="mt-10">Patientens kontaktuppgifter</h3>

                    <InputText
                      name="patientemail"
                      label="Patientens mejladress (Obligatorisk)"
                      type="email"
                      autocomplete={
                        formData.compensationfor === FOR_ME ? "email" : "off"
                      }
                    />

                    <InputText
                      name="patientemailrepeat"
                      label="Upprepa mejladress (Obligatorisk)"
                      type="email"
                      autocomplete={
                        formData.compensationfor === FOR_ME ? "email" : "off"
                      }
                    />

                    <InputText
                      name="patientphone"
                      type="tel"
                      label="Patientens telefonnummer (frivilligt)"
                      autocomplete={"tel"}
                      phonenumber
                    />
                  </>
                )}

                <p className="input-info-text -mt-2">
                  Om du saknar mejladress, då skickar du brev till oss.
                  Information om adress och vad brev ska innehålla finns på
                  sida:{" "}
                  <Link
                    to={
                      "https://www.ostgotatrafiken.se/sa-reser-du-med-oss/bestallningstrafik/fardtjanst--sjukresor/sjukresor-till-annan-region/"
                    }
                  >
                    Sjukresor till annan region.
                  </Link>
                </p>
              </Box>

              <div className="flex justify-center">
                <div className="w-full text-left flex items-center">
                  <BackButton />
                </div>
                <div className="w-full text-right">
                  <button
                    onClick={handleSubmitClick(formRef)}
                    className="btn secondary"
                    type="submit"
                  >
                    Gå vidare
                  </button>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
}
