import { useField } from "formik";

interface CheckboxProps {
  name: string;
  children: React.ReactNode;
}

const Checkbox = ({ name, children }: CheckboxProps) => {
  const [field, meta] = useField(name);

  return (
    <label
      tabIndex={0}
      htmlFor={name}
      className={`inline relative checkbox-label ${
        meta.touched && meta.error ? "has-error" : ""
      }`}
    >
      <div className="mb-2">
        {meta.touched && meta.error && (
          <div className="validation-error block ">{meta.error}</div>
        )}
      </div>
      <div className="flex">
        <input
          tabIndex={-1}
          id={name}
          {...field}
          type="checkbox"
          className="w-auto m-0 mr-4"
        />
        <div>{children}</div>
      </div>
    </label>
  );
};

export default Checkbox;
