import { Form } from "react-router-dom";
import Box from "../components/Box/Box";
import BackButton from "../components/Buttons/BackButton";

export default function Fail() {

  return (
    <div>
      <Form>
        <Box>
          <h2>Ett fel inträffade</h2>
          <p>Något verkar ha gått fel i din ansökan. Testa gärna igen.</p>
          <p>Du kan också skicka in din ansökan genom att mejla <a href="mailto:sjukreseansokan@ostgotatrafiken.se">sjukreseansokan@ostgotatrafiken.se</a></p>
        </Box>
        <div className="flex justify-center">
          <div className="w-full text-left flex items-center">
            <BackButton />
          </div>
        </div>
      </Form>
    </div>
  );
}
