import { Link } from "react-router-dom";
import logo from "../../loga_negative.svg";
import logomobile from "../../ot_logga_mobile.svg";

const Footer = () => {
  return (
    <footer className="footer pt-6 pb-12 lg:py-10 ">
      <div className="container flex md:block justify-between md:items-end">
        <div>
          <h2 className="text-white mb-4">Kontakta oss</h2>
          <ul>
            <li>
              Ring kundservice på{" "}
              <a className="inline-block" href="tel:0771211010">
                0771-21 10 10
              </a>
            </li>
            <li>
              <Link
                target="_blank"
                to={`https://www.ostgotatrafiken.se/kundservice/hur-kan-vi-hjalpa-dig/alla-kontaktvagar/`}
              >
                Fler satt att kontakta oss (Öppnas i ny flik)
              </Link>
            </li>
          </ul>
        </div>
        <div className="md:flex md:justify-end md:mt-6">
          <Link className="footer-logo-link" to={`/`}>
            <img src={logo} className="logo" alt="logo" />
            <img src={logomobile} className="logo-mobile" alt="logo" />
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
