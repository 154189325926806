import { Form, Formik } from "formik";
import { useAtom } from "jotai";
import { Link, useNavigate } from "react-router-dom";
import Box from "../components/Box/Box";
import { MedicalTravelModel } from "../types/FormTypes";
import { formDataAtom } from "../App";
import { issueAtom } from "./Summary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import useFocusOnLoad from "../hooks/useFocusOnLoad";

export default function OtherUploads() {
  const h2Ref = useFocusOnLoad<HTMLHeadingElement>();
  const [formData, setFormData] = useAtom(formDataAtom);
  const [issue] = useAtom(issueAtom);
  const navigate = useNavigate();

  return (
    <div>
      <Formik<MedicalTravelModel>
        onSubmit={(values) => {
          setFormData({
            ...formData,
            ...values,
          });

          navigate("../fler-resor");
        }}
        initialValues={{
          ...formData,
        }}
        enableReinitialize
      >
        {({ values }) => (
          <Form>
            <Box fullwidth>
              <h2 ref={h2Ref} tabIndex={-1}>
                <FontAwesomeIcon
                  className="relative text-green text-2xl mr-6"
                  icon={solid("check")}
                />
                Tack för din ansökan!
              </h2>
              <div role="group">
                <p>
                  Vi har tagit emot din ansökan. Ditt ärendenummer är{" "}
                  <strong>{issue?.id ?? ""}</strong>. Uppge det om du kontaktar
                  vår kundservice om det här ärendet.
                </p>
              </div>
            </Box>
            <div className="text-center">
              <Link
                className="btn secondary inline-block"
                to={
                  "https://www.ostgotatrafiken.se/sa-reser-du-med-oss/bestallningstrafik/fardtjanst--sjukresor/sjukresor-till-annan-region/"
                }
              >
                Tillbaka till ostgotatrafiken.se
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
