import { FormikErrors } from "formik";
import { useRef } from "react";

interface ErrorListProps<T> {
  errors: FormikErrors<T>;
}

const getElementType = (inputName: string): string => {
  const inputElements = document.getElementsByName(inputName);
  if (inputElements.length > 0) {
    return (inputElements[0] as HTMLInputElement).type;
  }
  return "";
};

const scrollToInput = (inputName: string) => {
  const elementType = getElementType(inputName);
  if (elementType === "file") {
    const dropzoneElement = document.querySelector(
      ".dropzone"
    ) as HTMLButtonElement;
    if (dropzoneElement) {
      dropzoneElement.focus();

      const yOffset = -110;
      const y =
        dropzoneElement.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  } else if (elementType === "radio") {
    const radioGroup = document.getElementsByName(inputName);
    if (radioGroup.length > 0) {
      const firstRadioButton = radioGroup[0] as HTMLInputElement;
      firstRadioButton.focus();
      const yOffset = -110;
      const y =
        firstRadioButton.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  } else {
    const inputElement = document.getElementById(inputName);
    if (inputElement) {
      inputElement.focus();
      const yOffset = -110;
      const y =
        inputElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
    else {
      const sectionElement = document.getElementsByClassName(`${inputName}-summary`).item(0);
      if (sectionElement) {
        const btn = sectionElement.querySelector(".change-link") as HTMLButtonElement;
        if (btn) {
          btn.focus();
          const yOffset = -110;
          const y =
            sectionElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y, behavior: "smooth" });
        }
      }
    }
  }
};

const ErrorList = <T,>({ errors }: ErrorListProps<T>) => {
  const errorAmount = Object.values(errors).length;
  const errorListRef = useRef<HTMLUListElement>(null);

  return (
    <>
      <div tabIndex={-1} className="error-box-wrapper" role="status" lang="sv">
        {errorAmount > 0 && (
          <div className="error-box">
            <h3>Det finns {errorAmount} fel i formuläret</h3>
            <ul ref={errorListRef}>
              {Object.entries(errors).map(([fieldName, error], index) => (
                <li key={index}>
                  <a
                    onClick={(event) => {
                      event.preventDefault();
                      scrollToInput(fieldName);
                    }}
                    href={`#${fieldName}`}
                  >
                    <>{error}</>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default ErrorList;
