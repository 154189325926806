import { useField, useFormikContext } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { SingleFileUploadWithProgress } from "./SingleFileUploadWithProgress";
import { UploadableFile } from "../../types/FormTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { MedicalVisit } from "../../types/FormTypes";

let currentId = 0;

function getNewId() {
  return ++currentId;
}

interface Props {
  inputName: keyof MedicalVisit;
  inputId: string;
  label: string;
  type: string;
  multiple: boolean;
  currentFiles: UploadableFile[];
}

const defaultProps: Props = {
  inputName: "certificate",
  inputId: "",
  label: "",
  type: "bilagor",
  multiple: true,
  currentFiles: [],
};

export function MultipleFileUploadField(props: Props) {
  const { inputId, label, type, multiple, currentFiles, inputName } = props;
  const { errors, touched } = useFormikContext<MedicalVisit>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, __, helpers] = useField(inputName);
  const [files, setFiles] = useState<UploadableFile[]>(currentFiles ?? []);
  const [numRejectedFiles, setNumRejectedFiles] = useState(0);
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);
  const [hasDot, setHasDot] = useState(true);
  const [fileDeleted, setFileDeleted] = useState("");
  const [singleFileErrorMessage, setSingleFileErrorMessage] = useState("");
  const [stagedFiles, setStagedFiles] = useState(0);

  const numFiles = files.length;
  const errorMsg =
    "Du kan bara bifoga ett intyg i taget. <br/> <br/> Om du har flera intyg och vill ansöka om ersättning\nför flera vårdbesök samtidigt, kommer du att få en fråga om det senare i formuläret";
  const errorMsgPrefix =
    " <br/> <br/> Du behöver ta bort nuvarande fil för att kunna lägga till en ny.";

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      setFileDeleted("");

      if (multiple) {
        setFiles((curr) => [
          ...curr,
          ...acceptedFiles.map(createUploadableFile),
        ]);
      } else {
        if (acceptedFiles.length === 0) {
          // Show error message for multiple files
          setSingleFileErrorMessage(errorMsg);
        } else if (acceptedFiles.length === 1) {
          setStagedFiles(acceptedFiles.length);
          if (stagedFiles === 1) {
            setSingleFileErrorMessage(errorMsg + errorMsgPrefix);
          } else {
            const file = acceptedFiles[0];
            setFiles([createUploadableFile(file)]);
            // Clear error message if single file is added
            setSingleFileErrorMessage("");
          }
        }
      }

      setRejectedFiles(rejectedFiles);
      setNumRejectedFiles(rejectedFiles.length);
      // Used to make screen readers repeat text even if its identical
      setHasDot(!hasDot);
    },
    [hasDot, multiple]
  );

  useEffect(() => {
    setTimeout(() => {
      helpers.setValue(files);
    }, 0);
  }, [files]);

  const onDelete = useCallback(
    (file: File) => {
      setFiles((curr) => curr.filter((fw) => fw.file !== file));
      setFileDeleted(`Filen ${file.name} har tagits bort`);
      setStagedFiles(0);
      setRejectedFiles([]);
      setNumRejectedFiles(0);
      setSingleFileErrorMessage("");

      // Used to make screen readers repeat text even if its identical
      setHasDot(!hasDot);
    },
    [hasDot]
  );

  const { getRootProps, getInputProps } = useDropzone({
    multiple: multiple,
    // maxFiles: multiple ? undefined : 1,
    // disabled: maxFilesReached,
    onDrop,
    accept: {
      "image/jpg": [],
      "image/jpeg": [],
      "image/pjpeg": [],
      "image/png": [],
      "image/apng": [],
      "image/svg+xml": [],
      "image/tiff": [],
      "image/webp": [],
      "image/pdf": [],
      "image/gif": [],
      "image/bmp": [],
      "application/pdf": [],
      "application/doc": [],
      "application/docx": [],
      "application/xml": [],
      "application/msword": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [],
    },
    maxSize: 8000 * 1024,
  });

  return (
    <React.Fragment>
      <label
        id={`${inputName}Id`}
        className={`h3 file-label}`}
        htmlFor={inputName}
      >
        <span className="block">{label}</span>
        {touched[inputName] && errors[inputName] && (
          <span className="validation-error">
            <>{errors[inputName]}</>
          </span>
        )}
        {singleFileErrorMessage.length > 1 && (
          <span
            className="validation-error"
            dangerouslySetInnerHTML={{ __html: singleFileErrorMessage }}
          />
        )}
      </label>

      <section className="fileupload">
        {/* //Screen reader text */}
        <div lang="sv" aria-live="polite" className="show-for-sr mb-4">
          {fileDeleted}.
          {numFiles > 0 ? (
            <span>
              Du har bifogat totalt, {numFiles}, {type}
            </span>
          ) : (
            ` Du har inga bifogade ${type}`
          )}
          {numRejectedFiles > 0 ? (
            <div>
              {rejectedFiles.map((file) =>
                file.errors.map((error, i) => (
                  <div key={i}>
                    Filen {file.file.name} gick inte att ladda upp.
                    {error.code === "file-too-large" ? (
                      <> Filen är för stor. maxstorlek är 8 megabyte</>
                    ) : error.code === "file-invalid-type" ? (
                      <>Vi tar inte emot filer av typen {file.file.type}</>
                    ) : (
                      ""
                    )}
                  </div>
                ))
              )}
            </div>
          ) : (
            ""
          )}
          <span className="dot">{hasDot ? ". " : ""}</span>
        </div>

        <button
          aria-labelledby={`${inputName}Id`}
          type="button"
          onClick={(event) => {
            event.preventDefault();
          }}
          tabIndex={0}
          {...getRootProps({
            className: `dropzone icon-link`,
          })}
        >
          <input
            name={inputName}
            id={inputId}
            {...getInputProps()}
            aria-required="true"
          />
          <div className="inner-dropzone-desktop hidden md:block">
            <FontAwesomeIcon icon={solid("file-arrow-up")} />
            <span className="click-here">
              {/* <span className="show-for-sr">{label}. </span> */}
              <span aria-hidden="true">Klicka för att välja bilaga</span>
            </span>
            <span aria-hidden="true">eller dra och släpp bilaga</span>
          </div>
          <div className="inner-dropzone-mobile flex md:hidden">
            <div className="mobile-photo">
              <FontAwesomeIcon icon={solid("camera")} />
              Ta bild eller välj fil
            </div>
          </div>
        </button>
        {numFiles > 0 && (
          <h3 className="mt-6">
            {type.charAt(0).toUpperCase() + type.slice(1)} du bifogar (
            {numFiles})
          </h3>
        )}
        <ul className="file-list mt-2">
          {files.map((fileWrapper, i) => (
            <li key={`${fileWrapper.id}-${i}`}>
              <SingleFileUploadWithProgress
                onDelete={onDelete}
                file={fileWrapper.file}
              />
            </li>
          ))}
        </ul>
        {rejectedFiles.some((file) =>
          file.errors.some(
            (error) =>
              error.code === "file-too-large" ||
              error.code === "file-invalid-type"
          )
        ) && (
          <ul className="file-list mt-8">
            {rejectedFiles.flatMap((file) =>
              file.errors.map((error, i) => {
                if (
                  error.code === "file-too-large" ||
                  error.code === "file-invalid-type"
                ) {
                  return (
                    <li key={`${file.file.name}-${i}`}>
                      <div className="validation-error error-info block">
                        <FontAwesomeIcon icon={solid("info")} />
                        {error.code === "file-too-large" && (
                          <>
                            Det gick inte att ladda upp filen{" "}
                            <span className="sans-bold">{file.file.name}</span>{" "}
                            för den är för stor. Vi tar inte emot filer större
                            än 8 mb
                          </>
                        )}
                        {error.code === "file-invalid-type" && (
                          <>
                            Det gick inte att ladda upp filen{" "}
                            <span className="sans-bold">{file.file.name}</span>.{" "}
                            <br />
                            Vi tar inte emot filer av den typen
                          </>
                        )}
                      </div>
                    </li>
                  );
                }
                return null;
              })
            )}
          </ul>
        )}
      </section>
    </React.Fragment>
  );
}

function createUploadableFile(file: File): UploadableFile {
  let ufile = {
    id: getNewId(),
    file,
    data: "",
    size: file.size,
    errors: [],
  };
  loadFileData(file, (data) => {
    ufile.data = data;
  });
  return ufile;
}

function loadFileData(file: File, cb: (value: string) => void) {
  async function doThing() {
    const data = await getBinaryAsBase64(file);
    cb(data);
  }
  doThing();
}

function getBinaryAsBase64(file: File) {
  return new Promise<string>((res, rej) => {
    const reader = new FileReader();
    reader.onloadend = (event) => {
      if (event.target instanceof FileReader) {
        res(window.btoa(event.target.result as string));
      } else {
        rej(new Error("Failed to read file."));
      }
    };
    reader.onerror = (evt) => rej(evt);
    reader.readAsBinaryString(file);
  });
}
MultipleFileUploadField.defaultProps = defaultProps;
