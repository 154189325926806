import { Form, Formik, Field } from "formik";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import Box from "../components/Box/Box";
import { MedicalVisit } from "../types/FormTypes";
import * as Yup from "yup";
import Progressbar from "../components/Progressbar/Progressbar";
import { medicalVisitAtom } from "./AddCertificate";
import BackButton from "../components/Buttons/BackButton";
import ErrorList from "../components/Form/ErrorList";
import { useRef } from "react";
import { handleSubmitClick } from "../components/Form/FormUtils";
import { useIsAddMoreTrue } from "./AddMore";
import useFocusOnLoad from "../hooks/useFocusOnLoad";

export const BY_PRIVATE = "byPrivate";
export const BY_PUBLIC = "byPublic";
export const BY_BOTH = "byBoth";

export default function TravelDetails() {
  const h2Ref = useFocusOnLoad<HTMLHeadingElement>();
  const [formData, setFormData] = useAtom(medicalVisitAtom);
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const [isAddMoreTrue] = useIsAddMoreTrue();

  return (
    <div>
      <Formik<MedicalVisit>
        onSubmit={(values) => {
          setFormData({
            ...formData,
            ...values,
          });

          if (values.wayOfTravel === BY_PRIVATE) {
            navigate("../bil");
          } else if (values.wayOfTravel === BY_PUBLIC) {
            navigate("../kollektivtrafik");
          } else if (values.wayOfTravel === BY_BOTH) {
            navigate("../bil-och-kollektivtrafik");
          }
        }}
        initialValues={{
          ...formData,
        }}
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object().shape({
          wayOfTravel: Yup.string().test(
            "at-least-one-radio-selected",
            "Vänligen välj ett svaralternativ för att gå vidare till nästa fråga",
            (value) => {
              return (
                value === BY_PRIVATE || value === BY_PUBLIC || value === BY_BOTH
              );
            }
          ),
        })}
      >
        {({ errors, values }) => (
          <>
            <div className="flex justify-between items-center">
              <div className="flex justify-center h-14 md:h-auto mb-1">
                <div className="w-full text-left md:hidden">
                  <BackButton />
                </div>
              </div>

              <div className="w-40 xs:w-52 md:w-full max-w-xs md:mb-4">
                {isAddMoreTrue ? (
                  <Progressbar percentage={"84"} />
                ) : (
                  <Progressbar percentage={"42"} />
                )}{" "}
              </div>
            </div>
            <Form ref={formRef} noValidate>
              <Box>
                <h2 ref={h2Ref} tabIndex={-1} id="wayOfTravelHeading">
                  Om resan
                </h2>

                <ErrorList errors={errors} />

                <div role="group" aria-labelledby="wayOfTravelHeading">
                  <fieldset className="radio-group">
                    <legend>
                      <span>
                        Hur reste du till och från vårdenheten? (Obligatorisk)
                      </span>
                    </legend>
                    {errors.wayOfTravel && (
                      <span
                        aria-describedby="forMe forOther"
                        className="validation-error"
                      >
                        {errors.wayOfTravel.toString()}
                      </span>
                    )}

                    <div className={errors.wayOfTravel ? "has-error" : ""}>
                      <Field
                        id="withCar"
                        type="radio"
                        name="wayOfTravel"
                        value={BY_PRIVATE}
                        required

                        // checked={values.wayOfTravel === BY_PRIVATE}
                      />
                      <label htmlFor="withCar">Med privatbil</label>
                    </div>

                    <div className={errors.wayOfTravel ? "has-error" : ""}>
                      <Field
                        id="withPublicTransport"
                        type="radio"
                        name="wayOfTravel"
                        value={BY_PUBLIC}
                        // checked={values.wayOfTravel === BY_PUBLIC}
                      />
                      <label htmlFor="withPublicTransport">
                        Med kollektivtrafik (tåg, buss, båt), taxi eller flyg
                      </label>
                    </div>

                    <div className={errors.wayOfTravel ? "has-error" : ""}>
                      <Field
                        id="withBoth"
                        type="radio"
                        name="wayOfTravel"
                        value={BY_BOTH}
                        // checked={values.wayOfTravel === BY_BOTH}
                      />
                      <label htmlFor="withBoth">
                        Både privatbil och kollektivtrafik, taxi eller flyg{" "}
                      </label>
                    </div>
                  </fieldset>
                </div>
              </Box>

              <div className="flex justify-center">
                <div className="w-full text-left flex items-center">
                  <BackButton />
                </div>
                <div className="w-full text-right">
                  <button
                    onClick={handleSubmitClick(formRef)}
                    className="btn secondary"
                    type="submit"
                  >
                    Gå vidare
                  </button>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
}
