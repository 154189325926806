import React from "react";

function Translate() {
  return (
    <div className="App1">
      <h1 className="">Translate</h1>
      <p>
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequatur
        esse, harum expedita at ut mollitia illo! Harum ipsa accusantium, rerum
        provident quos quod quis excepturi unde deleniti exercitationem velit
        voluptas.
      </p>
    </div>
  );
}

export default Translate;
